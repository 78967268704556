@tailwind base;
@tailwind components;
@tailwind utilities;



.tablestyle {
    font-size: 11pt; 
    font-family: Arial;
    border-collapse: collapse; 
    border: 1px solid silver;

}

.tablestyle td, th {
    padding: 5px;
}

.tablestyle tr:nth-child(even) {
    background: #E0E0E0;
}

.tablestyle tr:hover {
    background: silver;
    cursor: pointer;
}